import React from "react";
import Moment from "react-moment";
import { DIContainer, withDI } from "../hooks/withDI";
import getVestingSchedules from "../queries/getVestingSchedules";
import { SubgraphService } from "../services/subgraph";
import { MathService } from "../services/math";
import { PnotifyService } from "../services/pnotify.service";
import { LockData } from "../components/partials/LockRow";
import { PrivateVestingsBread } from "../components/modules/breadcrumbs/locks/PrivateVestingBread";
import { combineLatest, Subscription } from "rxjs";

export type IDOLocks = DIContainer & LockData & PresaleData & {};


export type PresaleData = {
  hardcap: string;
  projectInfo: string;
  totalCollected: string;
};

interface State {
  ticker: string | null;
  pendingLocks: LockData[];
  pastLocks: LockData[];
  connectedAddress: string | null;
  token: { id: string | null, symbol: string | null, decimals: number | null, name: string | null };
  collected: string | null;
  percentage: number | null;
}

class Lock extends React.Component<IDOLocks, State> {
  private subscription: Subscription | null = null;

  tokenAddress: string | undefined = undefined;
  math: MathService;
  pnotify: PnotifyService;


  constructor(props: IDOLocks) {
    super(props);
    this.math = this.props.mathImpl;
    this.pnotify = this.props.pnotifyImpl;

    this.state = {
      ticker: null,
      pendingLocks: [],
      pastLocks: [],
      token: { id: null, symbol: null, decimals: null, name: null },
      connectedAddress: null,
      collected: null,
      percentage: null,
    };

    this.tokenAddress = this.props.routerParams.address;
  }

  async getData(): Promise<void> {
    const subgraph: SubgraphService = this.props.subgraphImpl;
    const wallet = this.props.walletImpl;

    const connectedAddress = wallet.connectedWallet;

    if (connectedAddress) {
      subgraph
        .runQuery(wallet.connectedChain!, getVestingSchedules, {
          token: this.tokenAddress,
          beneficiary: connectedAddress,
        })
        .then(async (data) => {
          this.setState({
            token: data.vestingSchedules[0].token,
            ticker: data.vestingSchedules[0].token.symbol,
            connectedAddress,
          });

          const pendingLocks: LockData[] = [];
          const pastLocks: LockData[] = [];

          for (let i = 0; i < data.vestingSchedules.length; i++) {
            const vestingSchedule = data.vestingSchedules[i];

            const currentTime = parseInt(new Date().getTime() / 1000 + "");
            const started = currentTime >= parseInt(vestingSchedule.start);

            if (vestingSchedule.completed || vestingSchedule.revokedTime !== '0') {
              pastLocks.push(vestingSchedule);

            } else {
              pendingLocks.push(vestingSchedule);
              if (started) {
                vestingSchedule.claimable = await wallet.releasableFromVesting(vestingSchedule.vesting,
                  vestingSchedule.vestingScheduleId, vestingSchedule.token.decimals);
              } else {
                vestingSchedule.claimable = '0';
              }
            }
          }

          this.setState({ pendingLocks, pastLocks });
        })
        .catch(() => {
          this.setState({ pendingLocks: [] });
        });
    }

    if (this.tokenAddress !== undefined) {
      this.setState({ ticker: this.state.token.symbol });
    }
  }

  componentDidMount() {
    const ipfs = this.props.ipfsImpl;

    this.subscription = combineLatest(
      [this.props.walletImpl.$connectedWallet, this.props.walletImpl.$connectedChain]
    ).subscribe(() => {
      void this.getData();
    });

    ipfs.getJsonFile<State>(this.props.projectInfo).then((data) => {
      this.setState(data);
    });

    // this.setState({
    //    percentage: wallet.getPercentage(this.props.totalCollected, this.props.hardcap)
    // });

  }

  componentWillUnmount() {
    this.subscription?.unsubscribe();
  }


  async claim(vestingAddress: string, scheduleId: string, claimable: string, symbol: string) {
    (window as any).startLoading();

    this.props.walletImpl
      .claimFromVesting(vestingAddress, scheduleId)
      .then(() => {
        this.pnotify.success({ text: `Claimed ${claimable} ${symbol}` });
        this.getData();
      })
      .catch((e) => {
        console.error(e);
        this.pnotify.error({ text: "Claim failed" });
      })
      .finally(() => {
        (window as any).stopLoading();
      });
  }

  render() {
    if (!this.tokenAddress) {
      return (
        <div>
          <div className="project_details_section">
            <div className="container">
              <div className="row flex-row-reverse">
                <div className="col-md-12 text-center">
                  <h2>Not found</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className="project_details_section private-vesting">
          <div className="container">
            <div>
              <div className="private-vestings-breadcrumbs"><PrivateVestingsBread ticker={this.state.ticker}/></div>
              <h1>Vesting of <span className="vesting-token">{this.state.ticker}</span></h1>
              <div className="beneficiary">{this.state.connectedAddress}</div>

              {this.state.pendingLocks.length > 0 ? (
                <div className="project_details_allocations_table_section pt-50">
                  <h2>
                    Pending Vestings&nbsp;
                    <span>
                    <img
                      src="/assets/images/icons/steps.png"
                      alt=""
                      className="img-fluid"
                    />
                  </span>
                  </h2>
                  <div className="ProjectDetails2_AllocationsTable">
                    <div className="ProjectDetails2_AllocationsTableHeadings vestings-desktop">
                      <ul>
                        <li className="column_2">Claimable</li>
                        <li className="column_3">Start Dist</li>
                        <li className="column_4">End Dist</li>
                        <li className="column_4">Total</li>
                        <li className="column_2">Actions</li>
                      </ul>
                    </div>

                    <div className="ProjectDetails2_AllocationsTableBody vestings">
                      {this.state.connectedAddress &&
                        this.state.pendingLocks.map(
                          (lock: LockData, index: number) => {
                            if (
                              lock.beneficiary.toLowerCase() ===
                              this.state.connectedAddress!.toLowerCase()
                            ) {
                              return (
                                <ul key={index}>
                                  <li className="column_2"><span className="vesting-mobile">Claimable<br/></span>
                                    {lock.claimable != null &&
                                      lock.claimable +
                                      " " +
                                      this.state.ticker}
                                  </li>
                                  <li className="column_3"><span className="vesting-mobile">Start Dist<br/></span>
                                    <Moment format="DD/MM/YYYY HH:MM:SS">
                                      {(Number(lock.start) + Number(lock.cliff)) *
                                        1000}
                                    </Moment>
                                  </li>
                                  <li className="column_4 capitalize"><span
                                    className="vesting-mobile">End Dist<br/></span>
                                    <Moment fromNow>
                                      {(Number(lock.start) +
                                          Number(lock.duration)) *
                                        1000}
                                    </Moment>
                                  </li>
                                  <li className="column_4"><span className="vesting-mobile">Total<br/></span>
                                    {lock.released != null &&
                                      lock.amount +
                                      " " +
                                      this.state.ticker}
                                  </li>
                                  <li className="column_2"><span className="vesting-mobile">Actions<br/></span>
                                    <button className="claimBtn"
                                            disabled={lock.claimable ? Number(lock.claimable) === 0 : true}
                                            onClick={() =>
                                              this.claim(lock.vesting, lock.vestingScheduleId, lock.claimable as string, lock.token.symbol)
                                            }
                                    >
                                      Claim
                                      <i className="icon-arrow_right"></i>
                                    </button>
                                  </li>
                                </ul>
                              )
                                ;
                            } else {
                              return '';
                            }
                          }
                        )}
                    </div>
                  </div>
                </div>
              ) : ''}

              {this.state.pastLocks.length > 0 ? (
                <div className="project_details_allocations_table_section pt-50">
                  <h2>
                    Vesting List&nbsp;
                    <span>
                    <img
                      src="/assets/images/icons/steps.png"
                      alt=""
                      className="img-fluid"
                    />
                  </span>
                  </h2>
                  <div className="ProjectDetails2_AllocationsTable">
                    <div className="ProjectDetails2_AllocationsTableHeadings">
                      <ul>
                        <li className="column_1">Total</li>
                        <li className="column_2">Released</li>
                        <li className="column_3">Start Distribution</li>
                        <li className="column_4">End Distribution</li>
                      </ul>
                    </div>

                    <div className="ProjectDetails2_AllocationsTableBody">
                      {this.state.connectedAddress &&
                        this.state.pastLocks.map((lock: LockData, index: number) => {
                          if (
                            lock.beneficiary.toLowerCase() ===
                            this.state.connectedAddress!.toLowerCase()
                          ) {
                            return (
                              <ul key={index}>
                                <li className="column_1">
                                  {lock.amount +
                                    " " +
                                    this.state.ticker}
                                </li>
                                <li className="column_2">
                                  <div>
                                    {lock.released +
                                      " " +
                                      this.state.ticker}
                                    {lock.revokedTime !== '0' ?
                                      <>
                                        <br/>
                                        (Revoked&nbsp;
                                        <Moment fromNow>
                                          {Number(lock.revokedTime) * 1000}
                                        </Moment>)
                                      </> : ''
                                    }
                                  </div>
                                </li>
                                <li className="column_3">
                                  <Moment format="DD/MM/YYYY HH:MM:SS">
                                    {(Number(lock.start) + Number(lock.cliff)) *
                                      1000}
                                  </Moment>
                                </li>
                                <li className="column_4 capitalize">
                                  <Moment fromNow>
                                    {(Number(lock.start) + Number(lock.duration)) *
                                      1000}
                                  </Moment>
                                </li>
                              </ul>
                            );
                          } else {
                            return '';
                          }
                        })}
                    </div>
                  </div>
                </div>
              ) : ''}
            </div>
          </div>
        </div>
      </div>

    );
  }
}

export default withDI(Lock);
