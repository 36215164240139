import React from "react";
import { RowData } from "../components/partials/Row";
import { withDI } from "../hooks/withDI";
import LiveLockRow from "../components/vestings/LiveLockRow";
import PastLockRow from "../components/vestings/PastLockRow";
import ComingLockRow from "../components/vestings/ComingLockRow";

interface State {
  isOwner: boolean;
}

class List extends React.Component<RowData, State> {

  constructor(props: RowData) {
    super(props);
    this.state = {
      isOwner: false,
    };
  }

  async componentDidMount() {
    // const wallet = this.props.walletImpl;
  }


  render(): React.ReactNode {
    return (
      <div id="vestings-page">
        <div className="dextools-previous-section pt-160 pb-80 md-pb-100">
          <div className="container">
            <h1 className="private-vestings">Private Vestings</h1>
            <div className="project-menu-area d-flex align-items-center justify-content-center">
              <div className="project-left-menu">
                <ul className="nav" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="tab-link active"
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#open-igo"
                      type="button"
                      role="tab"
                      aria-controls="open-igo"
                      aria-selected="true"
                    >
                      Active Unlocks
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="tab-link"
                      id="profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#upcoming"
                      type="button"
                      role="tab"
                      aria-controls="upcoming"
                      aria-selected="false"
                    >
                      Upcoming Unlocks
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="tab-link"
                      id="contact-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#past-igo"
                      type="button"
                      role="tab"
                      aria-controls="past-igo"
                      aria-selected="false"
                    >
                      Past Unlocks
                    </button>
                  </li>
                </ul>
              </div>
            </div>

            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="open-igo"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <div className="row align-items-center">
                  <LiveLockRow></LiveLockRow>
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="upcoming"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <div className="row align-items-center">
                  <ComingLockRow></ComingLockRow>
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="past-igo"
                role="tabpanel"
                aria-labelledby="contact-tab"
              >
                <div className="row align-items-center">
                  <PastLockRow></PastLockRow>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withDI(List);
