import { useEffect, useState } from "react";
import axios from "axios";

const DextPrice = () => {

    const [usd, setUsd] = useState([]);

    useEffect(() => {
        axios

          .get(`https://api.coinbase.com/v2/prices/DEXT-USD/buy`)
        .then((res) => {
            setUsd(res.data?.data.amount || '');
        })
    }, []);


  return <div className="dext-price"><img src="/assets/images/dextools-price.svg" alt="dextools-price-icon" /> ${usd}</div>;
};
export default DextPrice;