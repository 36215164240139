import { alert, defaultModules, error, info, notice, Options, success } from '@pnotify/core';
import * as PNotifyMobile from '@pnotify/mobile';

defaultModules.set(PNotifyMobile, {});


export class PnotifyService {
  private activeNotification: any;

  private static _instance: PnotifyService;

  public static getInstance() {
    if (PnotifyService._instance == null) {
      PnotifyService._instance = new PnotifyService();
    }

    return PnotifyService._instance;
  }

  private constructor() {
  }

  public error(options: Options | string) {
    if (!(options as Options)?.text) {
      options = {
        text: options,
      } as Options;
    }

    (options as Options).styling = 'custombright brighttheme';

    this.tryCloseActive();
    this.activeNotification = error(options);

    return this.activeNotification;
  }

  public success(options: Options | string) {
    this.tryCloseActive();
    this.activeNotification = success(options);

    return this.activeNotification;
  }

  public alert(options: Options | string) {
    this.tryCloseActive();
    this.activeNotification = alert(options);

    return this.activeNotification;
  }

  public info(options: Options | string) {
    this.tryCloseActive();
    this.activeNotification = info(options);

    return this.activeNotification;
  }

  public notice(options: Options | string) {
    this.tryCloseActive();
    this.activeNotification = notice(options);

    return this.activeNotification;
  }

  private tryCloseActive() {
    if (this.activeNotification) {
      this.activeNotification.close && this.activeNotification.close(true);
    }
  }
}