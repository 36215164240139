import { gql } from "graphql-request";


const getLiveVestingSchedulesQuery = gql`
  query getLiveVestingSchedules(
    $beneficiary: Bytes!
    $currentDate: BigInt!
    $limit: Int!
    $offset: Int!
    $orderBy: VestingSchedule_orderBy
    $orderDirection: OrderDirection
  ) {
    vestingSchedules(
      first: $limit
      skip: $offset
      orderBy: $orderBy
      orderDirection: $orderDirection
      where: {
        beneficiary: $beneficiary,
        start_lt: $currentDate,
        revokedTime: "0"
      }
    ) {
      id
      vestingScheduleId
      vesting
      token {
        id
        decimals
        name
        symbol
        totalSupply
      }
      beneficiary
      start
      cliff
      duration
      slicePeriodSeconds
      revocable
      revokedTime
      amount
      released
      completed
    }
  }
`;

export default getLiveVestingSchedulesQuery;
