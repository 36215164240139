import { Web3ReactProvider } from "@web3-react/core";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "./components/common/Footer";
import Header from "./components/common/Header";
import Details from "./layout/Details";
import Home from "./layout/Home";
import List from "./layout/List";
import Sendproject from "./layout/Sendproject";
import { Helmet } from "react-helmet";
import { WalletService } from "./services/wallet";
import { ScrollTop } from './components/common/ScrollTop';
import Locks from "./layout/Lock";
import Web3 from "web3";
import '@pnotify/core/dist/PNotify.css';
import '@pnotify/core/dist/BrightTheme.css';
import LockList from "./layout/LockList";

function getLibrary(provider: any) {
  WalletService.Web3Instance = new Web3(provider);
  return WalletService.Web3Instance;
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <>
    <Web3ReactProvider getLibrary={getLibrary}>
      <BrowserRouter>
        <ScrollTop>
          <Header></Header>
          <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="details/:address" element={<Details/>}/>
            <Route path="idos" element={<List/>}/>
            <Route path="locks" element={<LockList/>}/>
            <Route path="locks/:address" element={<Locks/>}/>
            <Route path="send-project" element={<Sendproject/>}/>
          </Routes>
        </ScrollTop>
      </BrowserRouter>
      <Footer></Footer>
      <Helmet>
        <script src="/assets/js/main.js" async></script>
      </Helmet>
    </Web3ReactProvider>
  </>
);
