import Web3, { HttpProvider } from "web3";
import { MathService } from "./math";
import { keccak256, parseEther, toUtf8Bytes } from "ethers";
import { BehaviorSubject } from "rxjs";
import { PnotifyService } from "./pnotify.service";

export class WalletService {
  static Web3Instance: any;

  private static _instance: WalletService;

  public static getInstance(math?: MathService, pnotify?: PnotifyService) {
    if (WalletService._instance == null) {
      WalletService._instance = new WalletService(math!, pnotify!);
    }

    return WalletService._instance;
  }

  private _web3ReadOnly = new Map<number, any>();
  private _factoryWallet = new Map<number, string>();
  eth: any;
  public $connectedWallet: BehaviorSubject<null | undefined | string>;
  public $connectedChain: BehaviorSubject<null | undefined | number>;

  private constructor(private math: MathService, private pnotify: PnotifyService) {
    this.$connectedWallet = new BehaviorSubject(undefined) as BehaviorSubject<null | undefined | string>;
    this.$connectedChain = new BehaviorSubject(null) as BehaviorSubject<null | undefined | number>;

    this._init();
  }

  private _init() {
    const chainIds = process.env.REACT_APP_SUPPORTED_CHAINS?.split(',').map(chainId => Number(chainId)) || [];
    const web3RPCs = process.env.REACT_APP_NODES?.split(',') || [];
    const factories = process.env.REACT_APP_FACTORY_ADDRESSES?.split(',') || [];

    let index = 0;

    for (let chainId of chainIds) {
      this._web3ReadOnly.set(chainId, new Web3(new HttpProvider(web3RPCs[index])));
      this._factoryWallet.set(chainId, factories[index]);
      index++;
    }
  }

  get factoryWallet() {
    return this._factoryWallet.get(this.connectedChain!)!;
  }

  getWeb3(): any {
    return WalletService.Web3Instance;
  }

  getReadOnlyWeb3(): any {
    return this._web3ReadOnly.get(this.connectedChain!);
  }

  async on(event: string, handler: (accounts: string[]) => void) {
    return this.getWeb3()?.provider.on(event, handler);
  }

  get connectedWallet() {
    return this.$connectedWallet.value;
  }

  get connectedChain() {
    return this.$connectedChain.value;
  }

  // async connectedWallet: Promise<string | null> {
  //   const web3 = this.getWeb3();
  //   // console.log(web3);
  //   if (web3) {
  //     const accounts = await web3.eth.getAccounts();
  //     // console.log(accounts);
  //     if (accounts.length > 0) {
  //       this.connectedWallet = accounts[0];
  //     }
  //   }
  //
  //   return this.connectedWallet;
  // }

  getVestingContract(address: string, readonly?: boolean): any {
    const abi = require("../abis/vesting.json");

    if (readonly) {
      return new (this.getReadOnlyWeb3().eth.Contract)(abi, address);
    }
    return new (this.getWeb3().eth.Contract)(abi, address);
  }

  getSaleFactoryContract(address: string, readonly?: boolean): any {
    const abi = require("../abis/saleFactory.json");

    if (readonly) {
      return new (this.getReadOnlyWeb3().eth.Contract)(abi, address);
    }

    return new (this.getWeb3().eth.Contract)(abi, address);
  }

  getSaleContract(address: string, readonly?: boolean): any {
    const abi = require("../abis/sale.json");

    if (readonly) {
      return new (this.getReadOnlyWeb3().eth.Contract)(abi, address);
    }
    return new (this.getWeb3().eth.Contract)(abi, address);
  }

  async getTokenERC20Balance(address: string, ERC20Address: string): Promise<boolean> {
    const abi = require("../abis/token.json");
    // ERC20 balance
    const ethProvider = new Web3(new Web3.providers.HttpProvider(process.env.REACT_APP_ETH_NODE!));
    const ethContract = new ethProvider.eth.Contract(abi, ERC20Address);
    const ERC20balance = parseInt(await ethContract.methods.balanceOf(address).call(), undefined);
    const ERC20decimals: number = await ethContract.methods.decimals().call();
    const ERC20HumanValue = this.math.toHumanValue(ERC20balance.toLocaleString("fullwide", { useGrouping: false }), ERC20decimals);

    return parseFloat(ERC20HumanValue) >= 25;
  }

  async getTokenBEP20Balance(address: string, BEP20Address: string): Promise<boolean> {
    const abi = require("../abis/token.json");
    // BEP20 balance
    const BSCProvider = new Web3(new Web3.providers.HttpProvider(process.env.REACT_APP_BSC_NODE!));
    const BSCContract = new BSCProvider.eth.Contract(abi, BEP20Address);
    const BEP20Balance = parseInt(await BSCContract.methods.balanceOf(address).call(), undefined);
    const BEP20Decimals: number = await BSCContract.methods.decimals().call();
    const BEP20HumanValue = this.math.toHumanValue(BEP20Balance.toLocaleString("fullwide", { useGrouping: false }), BEP20Decimals);

    return parseFloat(BEP20HumanValue) >= 1000;
  }

  async releasableFromVesting(vestingAddress: string, scheduleId: string, decimals: bigint): Promise<string | null> {
    const contract = await this.getVestingContract(vestingAddress, true);
    const releasable = (await this._wrapCall(await contract.methods.computeReleasableAmount, scheduleId));
    if (releasable != null) {
      return this.math.toHumanValue(this.math.toBigNumber(releasable.toString()).toFixed(), decimals);
    }

    return null;
  }

  async claimFromVesting(vestingAddress: string, scheduleId: string): Promise<void> {
    const contract = await this.getVestingContract(vestingAddress, false);
    const releasable = (await this._wrapCall(await contract.methods.computeReleasableAmount, scheduleId));
    const connectedWallet = this.connectedWallet;
    if (releasable != null) {
      await contract.methods.release(scheduleId, releasable).send({ from: connectedWallet });
    }
  }

  async getVestingClaim(saleAddress: string): Promise<number[]> {
    const contract = await this.getSaleContract(saleAddress, true);

    const result = (await this._wrapCall(await contract.methods.getVestingClaim));
    return result?.map(
      (val: string) => parseInt(val, undefined)
    ) || [];
  }

  private async _wrapCall(method: any, ...args: any[]): Promise<any> {
    let result = null;
    try {
      result = await method(...args).call();
    } catch (err) {
      const e = err as any;

      this.pnotify.error({
        hide: false,
        textTrusted: true,
        text: `An error has occurred while loading information.<br/>If the problem persists please <u><a href="https://t.me/DEXToolsCommunity" target="_blank">contact us</a></u>`
      });

      console.error(`Error: ${e?.cause || e?.result?.message || e?.message}`);
    }

    return result;
  }

  async buyTokens(saleAddress: string, amount: string): Promise<void> {
    const connectedWallet = this.connectedWallet;

    try {
      const params = {
        from: this.getWeb3().utils.toChecksumAddress(connectedWallet),
        to: this.getWeb3().utils.toChecksumAddress(saleAddress),
        value: parseEther(amount).toString(),
      };
      void await this.getWeb3().eth.sendTransaction(params);
    } catch (e) {
      throw e;
    }
  }

  async claim(saleAddress: string): Promise<void> {
    const contract = await this.getSaleContract(saleAddress, false);
    const connectedWallet = this.connectedWallet;
    await contract.methods
      .claim()
      .send({ from: connectedWallet });
  }

  async refund(saleAddress: string): Promise<void> {
    const contract = await this.getSaleContract(saleAddress, false);
    const connectedWallet = this.connectedWallet;
    await contract.methods
      .refund()
      .send({ from: connectedWallet });
  }

  async getClaimableTokens(saleAddress: string, decimals: number): Promise<string> {
    const contract = await this.getSaleContract(saleAddress, true);
    const wallet = this.connectedWallet;
    const claimable = (await this._wrapCall(contract.methods.getClaimableTokens, wallet)) || 0;

    return this.math.toHumanValue(claimable, decimals);
  }

  async getPendingClaimable(saleAddress: string, decimals: number): Promise<string> {
    const contract = await this.getSaleContract(saleAddress, true);
    const connectedWallet = this.connectedWallet;

    if (connectedWallet == null) {
      return '0';
    }

    const pendingClaimable = (await this._wrapCall(contract.methods.pendingClaimable, connectedWallet)) || 0;

    return this.math.toHumanValue(pendingClaimable, decimals);
  }

  async getClaimedTokens(saleAddress: string, decimals: number): Promise<string> {
    const connectedWallet = this.connectedWallet;

    if (connectedWallet == null) {
      return "0";
    }

    const contract = await this.getSaleContract(saleAddress, true);
    const claimed = (await this._wrapCall(contract.methods.claimedTokens, connectedWallet)) || 0;

    return this.math.toHumanValue(claimed, decimals);
  }

  async alreadyRefunded(saleAddress: string): Promise<number> {
    const connectedWallet = this.connectedWallet;
    if (connectedWallet == null) {
      return 0;
    }
    const contract = await this.getSaleContract(saleAddress, true);
    const participants = (await this._wrapCall(contract.methods.participants, connectedWallet)) || 0;
    return parseInt(participants, undefined);
  }

  async getClaimTiming(saleAddress: string): Promise<number> {
    const contract = await this.getSaleContract(saleAddress, true);
    const result = (await this._wrapCall(contract.methods.claimTiming)) || 0;
    return parseInt(result, undefined);
  }

  async getClaimCliffTime(saleAddress: string): Promise<number> {
    const contract = await this.getSaleContract(saleAddress, true);
    const result = (await this._wrapCall(contract.methods.claimCliffTime)) || 0;
    return parseInt(result, undefined);
  }

  getPercentage(current: string, total: string): number {
    return this.math.toBigNumber(current).times(this.math.toBigNumber(100)).dividedBy(total).toNumber();
  }

  toWhitelistedStatus(isWhitelisted: boolean): "PUBLIC" | "PRIVATE" {
    return isWhitelisted ? "PRIVATE" : "PUBLIC";
  }

  async walletWhitelisted(saleAddress: string, wallet: string): Promise<boolean> {
    if (!wallet) {
      return false;
    }
    const contract = await this.getSaleContract(saleAddress, true);
    return (await this._wrapCall(contract.methods.getWhitelistStatus, wallet)) || false;
  }

  applyWhitelist(saleAddress: string, wallet: string): void {

    console.log("applyWhitelist", saleAddress, wallet);

    const response = localStorage.getItem("appliedWhitelist") as string;

    let whitelists: { [key: string]: string[] }

    if (response == null) {
      whitelists = {};
    } else {
      whitelists = JSON.parse(response);
    }


    if (!(whitelists[saleAddress]?.length > 0)) {
      whitelists[saleAddress] = [wallet];
      localStorage.setItem("appliedWhitelist", JSON.stringify(whitelists));

    } else {
      let add = false;
      let wallets: string[] = [];

      Object.entries(whitelists).forEach((entry: [string, string[]]) => {
        if (entry[0] === saleAddress) {
          if (entry[1].indexOf(wallet) < 0) {
            add = true;
            wallets = entry[1];
          }
        }
      });

      if (add && wallets.length > 0) {
        wallets.push(wallet);
        whitelists[saleAddress] = wallets;
        localStorage.setItem("appliedWhitelist", JSON.stringify(whitelists));
      }
    }
  }

  checkWhitelistedWallet(saleAddress: string, wallet: string): boolean {

    const response = localStorage.getItem("appliedWhitelist") as string;

    let whitelists: { [key: string]: string[] }

    if (response == null) {
      whitelists = {};
    } else {
      whitelists = JSON.parse(response);
    }

    let whitelisted = false;

    Object.entries(whitelists).forEach((entry: [string, string[]]) => {
      if (entry[0] === saleAddress) {
        whitelisted = entry[1].indexOf(wallet) >= 0;
      }
    });
    return whitelisted;
  }

  async hasRole(role: string, factoryAddress: string, wallet: string): Promise<boolean> {
    if (!wallet) {
      return false;
    }

    const contract = await this.getSaleFactoryContract(factoryAddress, true);
    return (await this._wrapCall(contract.methods.hasRole, keccak256(toUtf8Bytes(role)), wallet)) || false;
  }
}
