import { gql } from "graphql-request";

const getPastVestingSchedules = gql`
  query getPastVestingSchedules(
    $beneficiary: Bytes!
  ) {
    vestingSchedules(
      where: {
        and: [
          {beneficiary: $beneficiary},
          {or: [{revokedTime_not: "0"}, {completed: true}]}
        ]
      }
    ) {
      id
      vestingScheduleId
      vesting
      token {
        id
        decimals
        name
        symbol
      }
      beneficiary
      start
      cliff
      duration
      slicePeriodSeconds
      revocable
      revokedTime
      amount
      released
      completed
    }
  }
`;

export default getPastVestingSchedules;
