import React from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { DIContainer, withDI } from "../../hooks/withDI";


type LockRowData = DIContainer & LockData & {
  type: "LIVE" | "COMING" | "PAST";
}

export type LockData = {
  id: string;
  vestingScheduleId: string;
  vesting: string;
  token: { id: string, symbol: string, decimals: number, name: string };
  beneficiary: string;
  start: string;
  cliff: number;
  duration: number;
  slicePeriodSeconds: number;
  revocable: boolean;
  revokedTime: string;
  amount: string;
  released: string;
  claimable?: string | null;
  completed: boolean;
};


interface State {
  ticker: string | null;
  completed: boolean | null;
  revoked: boolean | null;
  percentage: number | null;
  claimable: string | null;
  end: number | null;
}

class Row extends React.Component<LockRowData, State> {
  constructor(props: LockRowData) {
    super(props);
    this.state = {
      ticker: null,
      completed: null,
      revoked: false,
      percentage: null,
      claimable: null,
      end: null,
    };
  }

  async componentDidMount() {
    const wallet = this.props.walletImpl;

    const currentTime = parseInt(new Date().getTime() / 1000 + "");
    const started = currentTime >= parseInt(this.props.start);

    let claimable: string | null = '0';

    if (!this.props.completed && started && this.props.revokedTime === '0') {
      claimable = await wallet.releasableFromVesting(this.props.vesting, this.props.vestingScheduleId, BigInt(this.props.token.decimals));

      if (claimable == null) {
        claimable = '0';
      }
    }

    this.setState({
      ticker: this.props.token.symbol,
      completed: this.props.completed,
      revoked: this.props.revokedTime !== '0',
      percentage: wallet.getPercentage(this.props.released, this.props.amount),
      end: Number(this.props.start) + Number(this.props.duration),
      claimable,
    });
  }

  render(): React.ReactNode {
    return (
      <>
        {
          <Link
            to={"/locks/" + this.props.token.id}>
            <div className="previous-item hover-shape-border hover-shape-inner desktop-version">
              <div className="previous-gaming">
                <div className="previous-price">
                  <h4 className="mb-1">
                    {this.state.ticker ? this.state.ticker : "..."}
                  </h4>
                  <div className="dsc">
                    Claimable {this.state.claimable || "..."} ${this.state.ticker}
                  </div>
                </div>
                <div className="previous-chaining desktop">
                  <img className="logo-chain"
                       src="/assets/images/project/project-single-image.png"
                       alt="Chain"/>
                </div>
              </div>
              <div className="TotalRaisedProgressHeadings">
                <div className="rised-data">
                  <span>{this.state.percentage != null ? this.state.percentage.toFixed(2) : "..."} %</span>
                  <span>
                          Released {this.props.released || "..."}&nbsp;
                    {this.state.ticker || "..."} <span className="off">of</span>
                    {this.state.ticker
                      ? this.props.amount + " " + this.state.ticker
                      : "..."}
                        </span>
                </div>
                <div className="progress-inner">
                  <div className="progress">
                    <div
                      className="progress-bar progress-bar-striped"
                      role="progressbar"
                      aria-valuenow={this.state.percentage != null ? this.state.percentage : 0}
                      aria-valuemin={0}
                      aria-valuemax={100}
                      style={{
                        width: this.state.percentage != null
                          ? this.state.percentage.toFixed(2) + "%"
                          : 0 + "%",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="previous-raise">
                <div className="finish-div">
                  {this.props.type === "PAST" && <span className="finished">Finished&nbsp;
                    {this.props.start && this.props.type !== "PAST" && (
                      <Moment fromNow>{Number(this.props.start) * 1000}</Moment>
                    )}
                    {this.state.end && this.props.type === "PAST" && (
                      <Moment fromNow>{Number(this.state.end) * 1000}</Moment>
                    )}
                    {!this.props.start ||
                      !this.state.end ||
                      (!this.props.type && "...")}
                        </span>}
                  {this.props.type === "COMING" && <span className="coming">Will start&nbsp;
                    {this.props.start ? (
                      <Moment fromNow>{Number(this.props.start) * 1000}</Moment>
                    ) : (
                      "..."
                    )}
                    {!this.props.start ||
                      !this.state.end ||
                      (!this.props.type && "...")}
                        </span>}
                  {this.props.type === "LIVE" && <span className="live">Will finish&nbsp;
                    {this.props.start && this.props.type !== "LIVE" && (
                      <Moment fromNow>{Number(this.props.start) * 1000}</Moment>
                    )}
                    {this.state.end && this.props.type === "LIVE" && (
                      <Moment fromNow>{Number(this.state.end) * 1000}</Moment>
                    )}
                    {!this.props.start ||
                      !this.state.end ||
                      (!this.props.type && "...")}
                        </span>}
                </div>
              </div>
            </div>
            <div className="previous-item hover-shape-border hover-shape-inner mobile-version">
              <div className="previous-gaming mobile">
                <div className="previous-price">
                  <h4 className="mb-1">
                    {this.state.ticker ? this.state.ticker : "..."}
                  </h4>
                </div>
              </div>
              <div className="previous-raise-mobile">
                <div className="previous-chaining-mobile">
                  <img className="logo-chain"
                       src="/assets/images/project/project-single-image.png"
                       alt="Chain"/>
                </div>
                <div className="finish-div">
                  {this.props.type === "PAST" && <span className="finished">Finished&nbsp;
                    {this.props.start && this.props.type !== "PAST" && (
                      <Moment fromNow>{Number(this.props.start) * 1000}</Moment>
                    )}
                    {this.state.end && this.props.type === "PAST" && (
                      <Moment fromNow>{Number(this.state.end) * 1000}</Moment>
                    )}
                    {!this.props.start ||
                      !this.state.end ||
                      (!this.props.type && "...")}
                        </span>}
                  {this.props.type === "COMING" && <span className="coming">Will start&nbsp;
                    {this.props.start && this.props.type !== "COMING" && (
                      <Moment fromNow>{Number(this.props.start) * 1000}</Moment>
                    )}
                    {this.state.end && this.props.type === "COMING" && (
                      <Moment fromNow>{Number(this.state.end) * 1000}</Moment>
                    )}
                    {!this.props.start ||
                      !this.state.end ||
                      (!this.props.type && "...")}
                        </span>}
                  {this.props.type === "LIVE" && <span className="live">Will finish&nbsp;
                    {this.props.start && this.props.type !== "LIVE" && (
                      <Moment fromNow>{Number(this.props.start) * 1000}</Moment>
                    )}
                    {this.state.end && this.props.type === "LIVE" && (
                      <Moment fromNow>{Number(this.state.end) * 1000}</Moment>
                    )}
                    {!this.props.start ||
                      !this.state.end ||
                      (!this.props.type && "...")}
                        </span>}
                </div>
              </div>
              <div className="TotalRaisedProgressHeadings">
                <div className="rised-data">
                  <span>{this.state.percentage != null ? this.state.percentage.toFixed(2) : "..."} %</span>
                  <span>
                          {this.props.released != null ? this.props.released : "..."}&nbsp;
                    {this.state.ticker ? this.state.ticker : "..."} <span className="off">of</span>
                    {this.state.ticker
                      ? this.props.amount + " " + this.state.ticker
                      : "..."}
                        </span>
                </div>
                <div className="progress-inner">
                  <div className="progress">
                    <div
                      className="progress-bar progress-bar-striped"
                      role="progressbar"
                      aria-valuenow={this.state.percentage != null ? this.state.percentage : 0}
                      aria-valuemin={0}
                      aria-valuemax={100}
                      style={{
                        width: this.state.percentage != null
                          ? this.state.percentage.toFixed(2) + "%"
                          : 0 + "%",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        }
      </>
    );
  }
}

export default withDI(Row);
