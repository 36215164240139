import { GraphQLClient } from "graphql-request";

export class SubgraphService {
  private static _instance: SubgraphService;

  public static getInstance() {
    if (SubgraphService._instance == null) {
      SubgraphService._instance = new SubgraphService();
    }

    return SubgraphService._instance;
  }

  private _gqlClients = new Map<number, GraphQLClient>();

  private constructor() {
    this._initGraphQlClients();
  }

  private _initGraphQlClients() {
    const chainIds = process.env.REACT_APP_SUPPORTED_CHAINS?.split(',').map(chainId => Number(chainId)) || [];
    const subgraphUris = process.env.REACT_APP_SUBGRAPH_URIS?.split(',') || [];

    let index = 0;

    for (let chainId of chainIds) {
      this._gqlClients.set(chainId, new GraphQLClient(subgraphUris[index]));
      index++;
    }
  }

  async runQuery(chainId: number, query: any, params: any): Promise<any> {
    return await this._gqlClients.get(chainId)!.request(query, params);
  }
}
