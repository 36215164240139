import { gql } from "graphql-request";

const getComingVestingSchedules = gql`
  query getComingVestingSchedules(
    $beneficiary: Bytes!
    $currentDate: BigInt!
  ) {
    vestingSchedules(
      where: {
        beneficiary: $beneficiary,
        start_gt: $currentDate,
        revokedTime: "0"
      }
    ) {
      id
      vestingScheduleId
      vesting
      token {
        id
        decimals
        name
        symbol
      }
      beneficiary
      start
      cliff
      duration
      slicePeriodSeconds
      revocable
      revokedTime
      amount
      released
      completed
    }
  }
`;

export default getComingVestingSchedules;
