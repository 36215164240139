import Pagination from '@mui/material/Pagination';
import React from "react";
import { Subscription } from "rxjs";
import { DIContainer, withDI } from '../../hooks/withDI';
import getPastPresalesQuery from "../../queries/getPastPresales";
import { SubgraphService } from "../../services/subgraph";
import Row, { RowData } from "../partials/Row";

interface State {
  presales: RowData[] | null;
  dataPerPage: RowData[] | null;
  page: number | null;
  pages: number | null;
  size: number | null;
  isOwner: boolean;
}

type PastData = DIContainer & {
  isOwner: boolean;
}

class PastPresalesInRow extends React.Component<PastData, State> {
  private subscription: Subscription | null = null;

  constructor(props: PastData) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      presales: null,
      dataPerPage: null,
      page: 0,
      pages: 0,
      size: 2,
      isOwner: props.isOwner,
    };
  }

  async getData() {
    if (this.props.walletImpl.connectedChain != null) {
      const subgraph: SubgraphService = this.props.subgraphImpl;
      subgraph
        .runQuery(this.props.walletImpl.connectedChain, getPastPresalesQuery, {
          limit: 100,
          offset: 0,
          orderBy: "startDate",
          orderDirection: "asc",
          currentDate: parseInt(new Date().getTime() / 1000 + ""),
        })
        .then((data: { sales: any[] }) => {
          const pages = Math.floor((data.sales.length % this.state.size! > 0) ? data.sales.length / this.state.size! + 1 : data.sales.length / this.state.size!);
          const dataPerPage = data.sales.slice(0, this.state.size!);
          // console.log("aaaaa", { presales: data.sales, dataPerPage: dataPerPage, pages: pages, page: 1, })
          this.setState({ presales: data.sales, dataPerPage: dataPerPage, pages: pages, page: 1, });
          // this.setState({ presales: data.sales });
        });
    }
  }

  componentDidMount() {
    this.subscription = this.props.walletImpl.$connectedChain.subscribe(() => {
      void this.getData();
    });
  }

  componentWillUnmount() {
    this.subscription?.unsubscribe();
  }

  handleChange(event: React.ChangeEvent<unknown>, value: number) {
    const size = this.state.size!;
    const presales = this.state.presales!;
    const dataPerPage = presales.slice(value * size - 2, value * size + size - 2);
    // console.log("handlechange===", dataPerPage, presales, value * size - 1, size)
    this.setState({ page: value, dataPerPage: dataPerPage });
  };

  render() {
    return (
      <>
        {this.state.presales == null ?
          <h2>Loading...</h2>
          : this.state.presales.length === 0 ?
            <h4>Here will be shown the past presales...</h4>
            : this.state.dataPerPage?.map((presale: RowData) =>
              <Row
                key={presale.id}
                id={presale.id}
                token={presale.token}
                buyPrice={presale.buyPrice}
                hardcap={presale.hardcap}
                startDate={presale.startDate}
                endDate={presale.endDate}
                type="PAST"
                isOwner={this.state.isOwner}
                projectInfo={presale.projectInfo}
                totalCollected={presale.totalCollected}
                stopped={presale.stopped}
                whitelistEnabled={presale.whitelistEnabled}
              />
            )
        }
        <Pagination
          className="d-flex justify-content-center"
          count={this.state.pages!}
          page={this.state.page!}
          onChange={this.handleChange}
          variant="outlined"
        />
      </>
    );
  }
}

export default withDI(PastPresalesInRow);
