import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import { InjectedConnector } from "@web3-react/injected-connector";
import React, { useEffect, useState } from "react";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ComingSoonStaking from "../modules/tooltips/disabledStaking";
import ComingSoonPools from "../modules/tooltips/disabledPools";
import ComingSoonGovernance from "../modules/tooltips/disabledGovernance";
import ComingSoonCommunity from "../modules/tooltips/disabledCommunity";
import { NavLink } from "react-router-dom";
import { MathService } from "../../services/math";
import { PnotifyService } from "../../services/pnotify.service";
import { WalletService } from "../../services/wallet";
import { Image } from "react-bootstrap";

declare const $: any;

const SUPPORTED_CHAINS = process.env.REACT_APP_SUPPORTED_CHAINS?.split(',').map(chainId => Number(chainId)) || [];

export default function Header() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [selectedChainId, setSelectedChainId] = useState<null | number>(null);
  const [isSupportedNetwork, setIsSupportedNetwork] = useState(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const math = MathService.getInstance();
  const pnotify = PnotifyService.getInstance();
  const wallet = WalletService.getInstance(math, pnotify);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const chainIdToImage = (chainId: number | null) => {
    switch (chainId || SUPPORTED_CHAINS[0]) {
      case 84532:
      case 8453:
        return 'base.svg';
      case 56:
        return 'bsc.png';
      case 11155111:
        return 'ethereum-sepolia.jpeg';
      case 1:
      default:
        return 'ether.png';
    }
  }

  const chainIdToName = (chainId: number | null) => {
    switch (chainId || SUPPORTED_CHAINS[0]) {
      case 84532:
        return 'Base Sepolia';
      case 8453:
        return 'Base';
      case 56:
        return 'BNB';
      case 11155111:
        return 'Sepolia';
      case 1:
      default:
        return 'Ethereum';
    }
  }

  const handleClose = () => {
    setAnchorEl(null);
  };


  const hideMenuNav = () => {
    setIsNavOpen(false)
  }

  const {
    account,
    activate,
    active,
    connector,
    deactivate,
    chainId,
    error
  } = useWeb3React();

  useEffect(() => {
    if (error instanceof UnsupportedChainIdError) {
      setIsSupportedNetwork(false);
    }
  }, [error, chainId]);

  const injected = new InjectedConnector({
    supportedChainIds: SUPPORTED_CHAINS,
  });

  async function connect(__chainId: number | null, force = false) {
    const _chainId = __chainId || SUPPORTED_CHAINS[0];

    try {
      if (((_chainId !== chainId) || (_chainId !== selectedChainId)) && (active || force || error instanceof UnsupportedChainIdError)) {
        const web3Provider = await connector?.getProvider();

        await web3Provider?.request!({
          method: "wallet_switchEthereumChain",
          params: [
            {
              chainId: `0x${_chainId.toString(16)}`,
            },
          ],
        });


        setSelectedChainId(_chainId);
        setIsSupportedNetwork(true);
      } else if (_chainId !== selectedChainId) {
        setSelectedChainId(_chainId);
      }

      if ((error?.message == null && localStorage?.getItem("isWalletConnected") !== "false") || force) {
        void await activate(injected);
        localStorage.setItem("isWalletConnected", "true");
      }

      $("#walletModal").modal("hide");
    } catch (ex) {
      console.warn(ex);
    }
  }

  async function disconnect() {
    try {
      deactivate();
      wallet.$connectedWallet.next(null);
      localStorage.setItem("isWalletConnected", "false");
    } catch (ex) {
      console.warn(ex);
    }
  }

  const connectWalletOnPageLoad = async (activate: (connector: InjectedConnector) => Promise<void>, injected: InjectedConnector) => {
    if (localStorage?.getItem("isWalletConnected") === "true") {
      try {
        await activate(injected);
        localStorage.setItem("isWalletConnected", "true");
      } catch (ex) {
        console.warn(ex);
      }
    } else if (selectedChainId == null) {
      setSelectedChainId(SUPPORTED_CHAINS[0]);
    }
  }

  useEffect(() => {
    if (error?.message == null) {
      if (!active) {
        void connectWalletOnPageLoad(activate, injected);
      }

      if (account !== wallet.connectedWallet) {
        wallet.$connectedWallet.next(account);
      }
    }
  }, [activate, injected, active, error]);

  useEffect(() => {
    if ((active || !isSupportedNetwork) && (chainId == null && selectedChainId == null && wallet.connectedChain == null)) {
      setSelectedChainId(SUPPORTED_CHAINS[0]!);
    } else if (active) {
      if (wallet.connectedChain == null && chainId != null) {
        wallet.$connectedChain.next(chainId);
      } else if (chainId != null && wallet.connectedChain !== chainId) {
        wallet.$connectedChain.next(chainId);
      }

      if (selectedChainId !== chainId && chainId != null) {
        setSelectedChainId(chainId!);
      }

      if (!isSupportedNetwork) {
        setIsSupportedNetwork(true);
      }
    } else if (selectedChainId != null && wallet.connectedChain !== selectedChainId) {
      wallet.$connectedChain.next(selectedChainId);
    }
  }, [chainId, selectedChainId, isSupportedNetwork, active]);

  // const conReload = () => {
  //   setTimeout(function () {
  //     window.location.reload();
  //   }, 10); // 500 milliseconds = 3 seconds
  // }

  const [navbar, setNavbar] = useState(false)

  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  useEffect(() => {
    changeBackground();
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground)
  });

  return (
    <div>
      <header
        id="dextools-header"
        className={navbar ? "dextools-header-section active" : "dextools-header-section"}
      >
        <div className="menu-area menu-sticky">
          <div className="container-hero">
            <div className="heaader-inner-area d-flex justify-content-end align-items-center">
              <div className="dextools-logo-area  align-items-center">
                <div className="logo">
                  <NavLink to="/">
                    <img src="/assets/images/logo-png.png" alt="logo"/>
                  </NavLink>
                </div>
              </div>
              <div className="header-menu">
                <ul className="nav-menu">
                  <li>
                    <NavLink className={(navData) => (navData.isActive ? 'active' : '')} to="/">Home</NavLink>
                  </li>
                  <li className="menu-item-has-children">
                    <NavLink className={(navData) => (navData.isActive ? 'active' : '')} to="/idos">Launchpad</NavLink>
                    <ul className="sub-menu">
                      <li><NavLink className={(navData) => (navData.isActive ? 'active' : '')}
                                   to="/idos">Projects</NavLink></li>
                      <li><NavLink className={(navData) => (navData.isActive ? 'active' : '')} to="/send-project">Submit
                        your project</NavLink></li>
                    </ul>
                  </li>
                  <li>
                    <NavLink className={(navData) => (navData.isActive ? 'actives' : '')} to=/* "/staking" */"#"><span
                      className="disabled"><ComingSoonStaking/>{/* Staking */}</span></NavLink>
                  </li>
                  <li>
                    <NavLink className={(navData) => (navData.isActive ? 'actives' : '')} to=/* "/pools" */"#"><span
                      className="disabled"><ComingSoonPools/>{/* Pools */}</span></NavLink>
                  </li>
                  <li>
                    <NavLink className={(navData) => (navData.isActive ? 'actives' : '')}
                             to=/* "/governance" */"#"><span
                      className="disabled"><ComingSoonGovernance/>{/* Governance< */}</span></NavLink>
                    {/*   <ul className="sub-menu">
                            <li><a href="#">Vote</a></li>
                            <li><a href="#">Submit your proposal</a></li>
                        </ul> */}
                  </li>
                  <li>
                    <NavLink className={(navData) => (navData.isActive ? 'actives' : '')} to=/* "/community" */"#"><span
                      className="disabled"><ComingSoonCommunity/>{/* Community */}</span></NavLink>
                  </li>
                  <li className="menu-item-has-children" key={selectedChainId + '-image'}>
                    <NavLink to={`#${chainIdToName(selectedChainId).toLowerCase()}`}>
                      <Image width={23} height={23}
                             src={"/assets/images/chains/" + chainIdToImage(selectedChainId)}/>
                      {chainIdToName(selectedChainId)}
                    </NavLink>
                    <ul className="sub-menu">
                      {
                        SUPPORTED_CHAINS.map(_chainId => (
                          (_chainId !== (selectedChainId || SUPPORTED_CHAINS[0])! ?
                            <li key={_chainId + '-image'}>
                              <NavLink to={`#${chainIdToName(_chainId).toLowerCase()}`} onClick={e => {
                                connect(_chainId).then();
                                e.preventDefault();
                              }}>
                                <Image width={23} height={23}
                                       src={"/assets/images/chains/" + chainIdToImage(_chainId)}/> {chainIdToName(_chainId)}
                              </NavLink>
                            </li>
                            : '')
                        ))
                      }
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="dextools-btn-area" onClick={() => setIsNavOpen((prev) => !prev)}>
                <ul>
                  <li>
                    <div id="nav-expander" className="nav-expander bar">
                      <div className="bar">
                        <span className="dot1"></span>
                        <span className="dot2"></span>
                        <span className="dot3"></span>
                      </div>
                    </div>
                  </li>
                  <li>
                    {active ?
                      (<div className="disconnect-wallet">
                          <button
                            id="disconnect-wallet"
                            className="readon white-btn hover-shape"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                          >
                            <img className="wallet-button"
                                 src="/assets/images/icons/connect.png"
                                 alt="Icon"
                            />
                            <span className="btn-text">
                            {account?.substring(0, 6)}...{account?.substring(21, 26)}
                          </span>
                            <i className="icon-arrow_right"></i>
                          </button>
                          <Menu
                            id="disconnect-wallet"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                              'aria-labelledby': 'basic-button',
                            }}
                          >
                            <MenuItem component={NavLink} to="/locks">Private Vestings</MenuItem>
                            <MenuItem className="disconnect-button" onClick={() => {
                              void disconnect();
                              // conReload()
                            }}>Disconnect</MenuItem>
                          </Menu>
                        </div>
                      ) :
                      isSupportedNetwork ? (
                        <button
                          type="button"
                          className="readon white-btn hover-shape"
                          data-bs-toggle="modal"
                          data-bs-target="#walletModal"
                        >
                          <span className="btn-text">Connect wallet</span>
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="readon white-btn danger-btn hover-shape"
                          onClick={() => connect(selectedChainId, true)}
                        >
                          <span className="btn-text">Connect to {chainIdToName(selectedChainId)}</span>
                        </button>
                      )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <nav
          className={isNavOpen ? "showMenuNav" : "hideMenuNav"}
          id="mobile-navbar-menu"
        >
          <div className="close-btn">
            <div id="nav-close2" className="nav-close" onClick={hideMenuNav}>
              <div className="line">
                <span className="line1"></span>
                <span className="line2"></span>
              </div>
            </div>
          </div>
          {/*      <div className="sidebar-logo mb-30">
            <a href="/">
              <img src="/assets/images/logo.svg" alt="" />
            </a>
          </div> */}
          <ul className="nav-menu">
            <li className="current-menu-item">
              <NavLink onClick={hideMenuNav} className={(navData) => (navData.isActive ? 'active' : '')}
                       to="/">Home</NavLink>
            </li>
            <li>
              <NavLink onClick={hideMenuNav} className={(navData) => (navData.isActive ? 'active' : '')}
                       to="/idos">Projects</NavLink>
            </li>
            <li>
              <NavLink onClick={hideMenuNav} className={(navData) => (navData.isActive ? 'active' : '')}
                       to="/send-project">Send Project</NavLink>
            </li>
            <li>
              {active ? (
                  <button
                    type="button"
                    className="readon black-shape-big connectWalletBtnforMobile"
                    onClick={disconnect}
                    // onClick={()=>{ disconnect(); conReload() }}
                  >
                    <img
                      src="/assets/images/icons/connect_white.png"
                      alt="Icon"
                    />
                    <span className="btn-text">
                    {account?.substring(0, 5)}...{account?.substring(21, 26)}
                  </span>
                  </button>
                ) :
                isSupportedNetwork ? (
                    <button
                      type="button"
                      className="readon black-shape-big connectWalletBtnforMobile"
                      data-bs-toggle="modal"
                      data-bs-target="#walletModal"
                    >
                      <span className="btn-text">Connect wallet</span>
                    </button>) :
                  (
                    <button
                      type="button"
                      className="readon black-shape-big danger-btn connectWalletBtnforMobile"
                      onClick={() => connect(selectedChainId)}
                      /* onClick={() => { connect(); conReload() }} */
                    >
                      <span className="btn-text">Connect to {chainIdToName(selectedChainId)}</span>
                    </button>
                  )}
            </li>
          </ul>
        </nav>
      </header>

      <div
        className="modal fade"
        id="walletModal"
        tabIndex={-1}
        aria-labelledby="walletModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="mb-20 modal-header">
              <h4 className="mb-0 modals-title" id="walletModalLabel">
                Connect Wallet
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="icon-x"></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="mb-20 connect-section">
                <ul className="heading-list">
                  <li onClick={() => {
                    void connect(selectedChainId!, true);
                    return false;
                  }}>
                    <a href="#metamask" onClick={(e) => e.preventDefault()}>
                      <span>
                        <img
                          src="/assets/images/icons/meta-mask.png"
                          alt="Meta-mask"
                        />
                      </span>
                      MetaMask
                    </a>
                  </li>
                  <li>
                    <a href="#walletconnect" onClick={(e) => e.preventDefault()}>
                      <span>
                        <img
                          src="/assets/images/icons/wallet.png"
                          alt="WalletConnect"
                        />
                      </span>
                      WalletConnect
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <style>{`
        nav.showMenuNav {
          right: 0 !important;
      }
      .hideMenuNav {
        right: -500px;
      }

    `}</style>
    </div>
  );
}
