import { gql } from "graphql-request";

const getVestingSchedules = gql`
  query getVestingSchedules(
    $token: String!
    $beneficiary: Bytes!
  ) {
    vestingSchedules(
      where: {
        token: $token,
        beneficiary: $beneficiary
      }
    ) {
      id
      vestingScheduleId
      vesting
      token {
        id
        decimals
        name
        symbol
      }
      beneficiary
      start
      cliff
      duration
      slicePeriodSeconds
      revocable
      revokedTime
      amount
      released
      completed
    }
  }
`;

export default getVestingSchedules;
