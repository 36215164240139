import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';

interface BreadCrumbData {
  ticker: string | null;
}

export class PrivateVestingsBread extends React.Component<BreadCrumbData, {}> {

  handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    event.preventDefault();
  }

  render(): React.ReactNode {
    return (
      <div role="presentation" onClick={this.handleClick}>
        <Breadcrumbs aria-label="breadcrumb">
          <MenuItem className="first" component={NavLink} to="/locks">Private Vestings</MenuItem>
          <MenuItem component={NavLink} to="#">Vesting of {this.props.ticker}</MenuItem>
        </Breadcrumbs>
      </div>
    );
  }
}
