import { Params, useParams } from 'react-router-dom';
import { IpfsService } from '../services/ipfs';
import { MathService } from '../services/math';
import { SubgraphService } from '../services/subgraph';
import { WalletService } from '../services/wallet';
import { PnotifyService } from "../services/pnotify.service";

export type DIContainer = {
  ipfsImpl: IpfsService;
  walletImpl: WalletService;
  mathImpl: MathService;
  pnotifyImpl: PnotifyService;
  subgraphImpl: SubgraphService;
  routerParams: Params;
}

export const withDI = (Component: any) => {
  return (props: any) => {
    const ipfs = IpfsService.getInstance();
    const math = MathService.getInstance();
    const pnotify = PnotifyService.getInstance();
    const wallet = WalletService.getInstance(math, pnotify);
    const subgraph = SubgraphService.getInstance();
    const params = useParams();

    return <Component
      ipfsImpl={ipfs}
      walletImpl={wallet}
      mathImpl={math}
      pnotifyImpl={pnotify}
      subgraphImpl={subgraph}
      routerParams={params}
      {...props}
    />;
  };
};