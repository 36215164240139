import React from "react";
import { DIContainer, withDI } from "../../hooks/withDI";
import { SubgraphService } from "../../services/subgraph";
import LockRow, { LockData } from "../partials/LockRow";
import Pagination from '@mui/material/Pagination';
import getPastVestingSchedules from "../../queries/getPastVestingSchedules";
import { combineLatest, Subscription } from "rxjs";

interface State {
  vestingSchedules: LockData[] | null;
  dataPerPage: LockData[] | null;
  page: number | null;
  pages: number | null;
  size: number | null;
}

type PastData = DIContainer & {}

class LiveVestingSchedulesRow extends React.Component<PastData, State> {
  private subscription: Subscription | null = null;

  constructor(props: PastData) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      vestingSchedules: null,
      dataPerPage: null,
      page: 0,
      pages: 0,
      size: 2,
    };
  }

  async getData() {
    const wallet = this.props.walletImpl;

    if (wallet.connectedWallet != null && wallet.connectedChain != null) {
      const connectedAddress = wallet.connectedWallet;

      const subgraph: SubgraphService = this.props.subgraphImpl;
      subgraph
        .runQuery(this.props.walletImpl.connectedChain!, getPastVestingSchedules, {
          limit: 100,
          offset: 0,
          orderBy: "start",
          orderDirection: "asc",
          beneficiary: connectedAddress,
        })
        .then(async (data: { vestingSchedules: any[] }) => {
          const pages = Math.floor((data.vestingSchedules.length % this.state.size! > 0) ? data.vestingSchedules.length / this.state.size! + 1 : data.vestingSchedules.length / this.state.size!);
          const dataPerPage = data.vestingSchedules.slice(0, this.state.size!);

          this.setState({ vestingSchedules: data.vestingSchedules, dataPerPage: dataPerPage, pages: pages, page: 1, });
        });
    }
  }

  componentDidMount() {
    this.subscription = combineLatest(
      [this.props.walletImpl.$connectedWallet, this.props.walletImpl.$connectedChain]
    ).subscribe(() => {
      void this.getData();
    });
  }

  componentWillUnmount() {
    this.subscription?.unsubscribe();
  }

  handleChange(event: React.ChangeEvent<unknown>, value: number) {
    const size = this.state.size!;
    const vestingsSchedules = this.state.vestingSchedules!;
    const dataPerPage = vestingsSchedules.slice(value * size - 2, value * size + size - 2);
    this.setState({ page: value, dataPerPage: dataPerPage });
  };

  render() {
    return (
      <>
        {this.state.vestingSchedules == null ?
          <h2>Loading...</h2>
          : this.state.vestingSchedules.length === 0 ?
            <h4>Here will be shown the past vestings...</h4>
            : this.state.dataPerPage?.map((vestingSchedule: LockData) =>
              <LockRow
                key={vestingSchedule.id}
                id={vestingSchedule.id}
                amount={vestingSchedule.amount}
                beneficiary={vestingSchedule.beneficiary}
                vestingScheduleId={vestingSchedule.vestingScheduleId}
                type="PAST"
                released={vestingSchedule.released}
                vesting={vestingSchedule.vesting}
                cliff={vestingSchedule.cliff}
                completed={vestingSchedule.completed}
                duration={vestingSchedule.duration}
                revocable={vestingSchedule.revocable}
                slicePeriodSeconds={vestingSchedule.slicePeriodSeconds}
                start={vestingSchedule.start}
                revokedTime={vestingSchedule.revokedTime}
                token={vestingSchedule.token}
              />
            )
        }
        <Pagination
          className="d-flex justify-content-center"
          count={this.state.pages!}
          page={this.state.page!}
          onChange={this.handleChange}
          variant="outlined"
        />
      </>
    );
  }
}

export default withDI(LiveVestingSchedulesRow);
